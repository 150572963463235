import { endpoints } from './endpoints';
import client from '../lib/crypto/client';

const getFetchBadAssSecure = () => {
    if ("object" === typeof window) {
        let getBadObj = ("object" === typeof window.badAssGuys) ? window.badAssGuys.badAssSecure : ""; // "6693614066929259"
        return getBadObj
    } else {
        return ""
    }
}

const fetchHeader = {
    "Content-Type": "application/json",
    "User-Agent": "WEB/MultipleCampaign",
    "user-agent": "WEB/MultipleCampaign",
    "UserAgent": "WEB/MultipleCampaign",
} //"appId":data
const fetchHeaderFile = { "Content-Type": "application/json", "mimeType": "multipart/form-data" }

const isCrypto = process.env.REACT_APP_CRYPTO === "on" ? true : true;

export default class Api {
    constructor() {
        this.crypto = new client(); //crypto instance
    }
    encrypt = (data) => {
        let encryptedText = this.crypto.encryptBody(data);
        return encryptedText;
    }

    fetch = (url, method, body) => {
        let opt = {
            method: method,
            headers: fetchHeader,
            body: body,
            //credentials: 'same-origin'
        }
        // let appid = "";

        let getBadAssGuySecure = getFetchBadAssSecure(); // fetch the bad ass secure guy
        if (getBadAssGuySecure) { // add the encrypted AES key to header
            opt.headers.meta = getBadAssGuySecure;
        }
        // isCrypto = true;
        if (body && isCrypto) {// add encrypted body if crypto is on
            let encryptedBody = { "body":body} //this.crypto.encryptBody(body) };
            console.log('encryptedBody', encryptedBody);
            opt.body = JSON.stringify(encryptedBody);
        }
        console.log('fetchSujeet', url, opt)

        return fetch(url, opt).then((response) => response.json())
    }

    fetchFile = (url, method, body) => {

        let opt = {
            method: method,
            headers: fetchHeaderFile,
            body: body,
            credentials: 'same-origin'
        }

        return fetch(url, opt).then((response) => response.json())

    }
    fetchNormal = (url, method, body) => {
        process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
        let opt = {
            method: method,
            headers: fetchHeader,
            body: body,
            //credentials: 'same-origin'
        }

      // console.log("OPT ------------------------------", opt);

        return fetch(url, opt).then((response) => response.json())
    }


    buildUrl = (path, urlType = "") => {
        if (urlType === "full") {
            return `${path}`;
        } else {
            return `${endpoints.baseUrl}${path}`;
        }
    }

}