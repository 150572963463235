
import "core-js/stable";
import "regenerator-runtime/runtime";
import React, { useEffect, useState } from 'react';
import Header from '../components/headerNew/header';
import Footer from '../components/footer/footer';
import Head from 'next/head'
import { useRouter } from "next/router";
import Axios from 'axios'
import 'bootstrap/dist/css/bootstrap.min.css';
import '/assets/css/fonts.css';
import '../styles/styles.css';
import '../styles/contact.css';
import { getUserMachineIp } from "../helper/methods";
import { osName } from 'react-device-detect';
import PreLoginApi from "../services/preLoginApi";
import useSeo from "../components/hooks/useSeo";

let api = new PreLoginApi();
let delay = process.env.NEXT_PUBLIC_SCRIPT_LOADING_DELAY;

const loadIframe = () => {
  let noScriptTag = document.getElementById('body-no-script-tag');
  let iframeTag = document.createElement('iframe');
  iframeTag.src = "https://www.googletagmanager.com/ns.html?id=GTM-NPBHR7";
  iframeTag.height = 0;
  iframeTag.width = 0;
  iframeTag.style.display = "none";
  iframeTag.style.visibility = "hidden";
  noScriptTag?.appendChild(iframeTag);
}

const loadGtm = () => {
  const gtmScript = document.createElement('script');
  gtmScript.type = "text/javascript";
  gtmScript.innerHTML = `(function(w,d,s,l,i){w[l] = w[l] || []; w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NPBHR7');`;
  document.head.appendChild(gtmScript);
}

const loadGtag1 = () => {
  const gtagScript = document.createElement('script');
  // gtagScript.setAttribute('async', true);
  gtagScript.src = "https://www.googletagmanager.com/gtag/js?id=UA-61001832-1";
  document.head.appendChild(gtagScript);
}

const loadGtag2 = () => {
  const gtagScript2 = document.createElement('script');
  gtagScript2.innerHTML = `window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'UA-61001832-1');`;

  document.head.appendChild(gtagScript2);
}

// const loadGtag3 = () => {
//   let gtagScript3 = document.createElement('script');
//   gtagScript3.type = "text/javascript";
//   gtagScript3.innerHTML = `
// (function(i,s,o,g,r,a,m,n){i.moengage_object=r;t={};q=function(f){return function(){(i.moengage_q=i.moengage_q||[]).push({f:f,a:arguments})}};f=['track_event','add_user_attribute','add_first_name','add_last_name','add_email','add_mobile','add_user_name','add_gender','add_birthday','destroy_session','add_unique_user_id','moe_events','call_web_push','track','location_type_attribute'],h={onsite:["getData","registerCallback"]};for(k
// in f){t[f[k]]=q(f[k])}for(k in h)for(l in h[k]){null==t[k]&&(t[k]={}),t[k][h[k][l]]=q(k+"."+h[k][l])}a=s.createElement(o);m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m);i.moe=i.moe||function(){n=arguments[0];return t};a.onload=function(){if(n){i[r]=moe(n)}}})(window,document,'script','https://cdn.moengage.com/webpush/moe_webSdk.min.latest.js','Moengage')


// Moengage = moe({
// app_id:"${app_id}",                    
// debug_logs: ${allow_debugging}
// });
// `;
//   document.head.appendChild(gtagScript3);
// };

function MyApp({ Component, pageProps, footerData }) {

  const router = useRouter();
  let { query } = router;

  const { title, pdesc, sdesc, keyData } = useSeo(footerData);
  var appSetting = '%7B%22openMode%22%3A%7B%22text%22%3A%22Talk%20to%20Alfred%22%2C%22height%22%3A450%2C%22width%22%3A320%7D%2C%22closeMode%22%3A%7B%22text%22%3A%22Talk%20to%20Alfred%22%2C%22width%22%3A300%2C%22displayType%22%3A%22circular%22%7D%2C%22common%22%3A%7B%22logo%22%3A%22//www.buildquickbots.com/widget/bots/fb23e46f7f61166dd84cb5e73857556d/04dcc76493f0d61f11f47ab34e9ae180/logo.png%22%2C%22imgDispType%22%3A%22rectangular%22%2C%22bgColor%22%3A%22%231A294E%22%2C%22fontSize%22%3A18%2C%22fontColor%22%3A%22%23FFFFFF%22%2C%22textColor%22%3A%22%23565656%22%7D%2C%22content%22%3A%7B%22bot%22%3A%22//www.buildquickbots.com/widget/bots/fb23e46f7f61166dd84cb5e73857556d/04dcc76493f0d61f11f47ab34e9ae180/bot.png%22%2C%22botBubbleColor%22%3A%22%23eeeeee%22%2C%22botFontColor%22%3A%22%231A294E%22%2C%22user%22%3A%22https%3A//www.gupshup.io/images/botwidget/ic_user.png%22%2C%22userBubbleColor%22%3A%22%231A294E%22%2C%22userFontColor%22%3A%22%23FFFFFF%22%2C%22pageTitle%22%3A%22Chat%20Bot%22%2C%22fontSize%22%3A18%2C%22fontFamily%22%3A%22Roboto%22%7D%2C%22config%22%3A%7B%22persistenceMenu%22%3A%22%22%2C%22perMenuImg%22%3A%22%22%2C%22attachment%22%3A%22No%22%2C%22widgetType%22%3A%22Text%22%2C%22msgEnc%22%3A%22No%22%2C%22webView%22%3A%22Yes%22%2C%22RDStatus%22%3A%22No%22%2C%22isResponsive%22%3A%22No%22%2C%22allowHtmlFromBot%22%3A%22Yes%22%7D%7D';
  var sessionID = "";
  var userID = "";
  var Role = "C";
  var token = "";
  var baseurl = process.env.NEXT_PUBLIC_BASEURL_CHATBOT_SDK
  var botType = process.env.NEXT_PUBLIC_CHATBOT_TYPE

  const [state, setstate] = useState({
    appSetting: appSetting, sessionID: sessionID, userID: userID
    , Role: Role, token: token, baseurl: baseurl
  })

  const getSessionId = async () => {
    let body = {
      "machineip": await getUserMachineIp(),
      "screensize": window.screen.width + ' X ' + window.screen.height,
      "osname": osName
    }
    let BASEURL = process.env.NEXT_PUBLIC_BASEURL_INIT_ROOT;
    Axios.post(`${BASEURL}api/init`, body).then(res => {
      let data = res.data.data
      setstate({ ...state, sessionID: data.appId })
    }).catch(error => {
      console.log(error, 'errorCatchappid')
    })
  }

  useEffect(() => {
    setTimeout(() => getSessionId(), 500);
    setTimeout(() => loadIframe(), delay);
    setTimeout(() => loadGtm(), delay);
    setTimeout(() => loadGtag1(), delay);
    // setTimeout(()=> loadGtag2(), delay);
  }, [])

  useEffect(() => {
    if (state.sessionID !== "") {

      let url = `https://www.gupshup.io/developer/bot/${botType}/setCreds?userid=${state.userID}&role=${state.Role}&sessionid=${(state.sessionID).toUpperCase()}&token=${state.token}&baseurl=${state.baseurl}`

      fetch(url, {
        method: "POST",
        headers: {
          "Authorization": "81cee6b3-f6fa-4e61-86ae-991b36c59954",
          "Accept": "application/x-www-form-urlencoded"
        }
      }).then(response => response.json()).then(data => {
        botUI();
      }).catch((error) => {
        console.error('Error:', error);
      });
      botUI();
      let scriptkey = process.env.NEXT_PUBLIC_CHATBOT_SCRIPT_KEY
      let scriptbrand = botType
      let scriptId = "gs-sdk"
      let scriptcallback = "tcAsyncInit"
      let scriptsrc = "https://www.buildquickbots.com/botwidget/v3/demo/static/js/sdk.js?v=3"
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = scriptsrc;
      script.setAttribute('brand', scriptbrand);
      script.setAttribute('key', scriptkey);
      script.setAttribute('callback', scriptcallback);
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      }
    }
  }, [state.sessionID])

  function botUI() {
    window.tcAsyncInit = function (e) {

      var obj = {
        roomid: state.sessionID,
        sender: {
          id: state.sessionID,
          name: "Me"
        },
        recipient: {
          id: state.sessionID,
          name: "Bot"
        },
        message: {
          text: "Hello",
          type: "text"
        }
      };
      var initObj = {

        botkey: process.env.NEXT_PUBLIC_CHATBOT_SCRIPT_KEY,
        appUISetting: state.appSetting,
        version: 'v3'
      }

      initObj.userObj = obj;
      e.init(initObj);
    };
  }

  let headerFlag = false;
  if (Object.keys(query).length > 0) {
    if (query.hasOwnProperty("view")) {
      let getVal = query["view"];
      if (getVal === "app") {
        headerFlag = true;
      }
    }
  }

  return (
    <React.Fragment>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="minimum-scale=1 width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content={pdesc} />
        <meta name="keywords" content={keyData} />

        {/* <meta name="description" content="Move a step closer towards financial stability with Motilal Oswal Mutual Fund. Experience difference with the best portfolio management services" /> */}

        <link rel="canonical" href="https://pms.motilaloswalmf.com/" />
        {/* <!-- Google Tag Manager --> */}
        {/* <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-NPBHR7');`,
          }}
      >
      </script> */}
        {/* <!-- End Google Tag Manager --> */}

        {/* <script async src="https://www.googletagmanager.com/gtag/js?id=UA-61001832-1"></script>
        <script dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'UA-61001832-1');`,
        }}>
        </script> */}

        {/* <title>Mutual Funds | Best Mutual Fund Investment in India - Motilal Oswal Mutual Funds</title> */}
        {/* <title>Portfolio Management Service In India - Motilal Oswal Mutual Fund</title> */}
        <title>{title}</title>

      </Head>
      {!headerFlag ? <Header /> : null}
      <Component {...pageProps} />
      {!headerFlag ? <Footer /> : null}
    </React.Fragment>
  )


}

MyApp.getInitialProps = async ({ Component, ctx }) => {
  try {
    let payload = {
      api_name: "SEODetails",
    };
    const footerRes = await api.SEODetails(payload); //await fetch('https://jsonplaceholder.typicode.com/todos');
    let footerData = {};
    footerData = footerRes;

    let pageProps = {};
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return { footerData, pageProps };
  } catch (e) {
    return { footerData: {}, pageProps: {} }
  }
};

export default MyApp;