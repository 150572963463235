
const TopJson = [
    {
        Heading: "Mutual Fund",
        url: `${process.env.NEXT_PUBLIC_MOTILAL_MF_MODULE_URL}`,
        internal: false,
        filter: "MF"
    },
    {
        Heading: "Index Fund",
        url: `${process.env.NEXT_PUBLIC_MOTILAL_MF_MODULE_URL}index-fund`,
        internal: false,
        filter: "Index"
    },
    {
        Heading: "ETF",
        url: `${process.env.NEXT_PUBLIC_MOTILAL_MF_MODULE_URL}etf`,
        internal: false,
        filter: "ETF"
    },
    {
        Heading: "PMS",
        url: `${process.env.NEXT_PUBLIC_PMS_MODULE_URL}`,
        internal: false,
        filter: "PMS"
    },
    {
        Heading: "AIF",
        url: `${process.env.NEXT_PUBLIC_AIF_MODULE_URL}`,
        internal: false,
        filter: "AIF"
    },
    {
        Heading: "PARTNER",
        url: `${process.env.NEXT_PUBLIC_PARTNER_MODULE_URL}`,
        internal: false,
        filter: "partner"
    },
    {
        Heading: "INVESTOR EDUCATION",
        url: `${process.env.NEXT_PUBLIC_MOTILAL_MF_MODULE_URL}investor-education/`,
        internal: false,
        filter: "investor"
    },
    {
        Heading: "INTERNATIONAL BUSINESS",
        url: `${process.env.NEXT_PUBLIC_MOTILAL_MF_MODULE_URL}InternationalBusiness`,
        internal: false,
        filter: "international"
    }
]

export default TopJson