import { useRouter } from 'next/router';
import React from 'react'

const useSeo = (footerData) => {
    const router = useRouter();

    let title = "";
    let pdesc = "";
    let sdesc = "";
    let keyData = "";
    let { asPath } = router;

    let breakPath = asPath?.split('?');
    let pathWithoutParams = breakPath?.[0] || '';

    if (Object.keys(footerData).length > 0) {
        if (footerData?.data?.success === 1) {
            let resObj;
            let objForTitleDesc;
            let defaultObjForTitleDesc = footerData?.data?.data?.["Mutual Fund"]?.['mf-home'];
            let pmsDefaultObjForTitleDesc = footerData?.data?.data?.["PMS"]?.['pms-home'];
            resObj = footerData?.data?.data?.["PMS"];

            let splittedPath = pathWithoutParams?.split('/');

            if (splittedPath?.length >= 2) {
                let length = splittedPath?.length;
                let lastPath = splittedPath?.[length - 1] || '';
                objForTitleDesc = resObj?.[lastPath] || footerData?.data?.data?.["Mutual Fund"]?.[lastPath] ||
                pmsDefaultObjForTitleDesc || defaultObjForTitleDesc;
            } else {
                objForTitleDesc = pmsDefaultObjForTitleDesc || defaultObjForTitleDesc;
            }

            if (objForTitleDesc) {
                title = objForTitleDesc.title;
                pdesc = objForTitleDesc.metaContent;
                sdesc = objForTitleDesc.shortDesc;
                keyData = objForTitleDesc.keywords;
            }
        }
    }

    return { title, pdesc, sdesc, keyData }
}
export default useSeo;