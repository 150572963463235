import Api from '.';
import { endpoints } from './endpoints';

export default class PreLoginApi extends Api {
    aboutUs(data) {
        let url = this.buildUrl(endpoints.preLogin.aboutUs, "full")
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    InterBusiness(data) {
        // console.log("In generateOtp", data)
        let url = this.buildUrl(endpoints.preLogin.internationalBusiness, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getAIFDetails(data) {
        // console.log("In generateOtp", data)
        let url = this.buildUrl(endpoints.preLogin.getAIFDetails, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getFaq(data) {
        // console.log("In generateOtp", data)
        let url = this.buildUrl(endpoints.preLogin.faq, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    NavCall(data) {
        // console.log("In generateOtp", data)


        let url = this.buildUrl(endpoints.preLogin.NavDetail, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    NavHistoricalfilter(data) {
        // console.log("In generateOtp", data)


        let url = this.buildUrl(endpoints.preLogin.HistoricalFilter, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    MFhome(data) {
        // console.log("In generateOtp", data)


        let url = this.buildUrl(endpoints.preLogin.MFHome, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response).catch(e=>{
            console.log(e)
        });
    }
    Category(data) {
        // console.log("In generateOtp", data)


        let url = this.buildUrl(endpoints.preLogin.FundCategory, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    CategoryNew(data) {
        // console.log("In generateOtp", data)


        let url = this.buildUrl(endpoints.preLogin.FundCategoryNew, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getMFfilter(data) {

        let url = this.buildUrl(endpoints.preLogin.GetFundsForFilter, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }

    fundDetail(data) {
       
        let url = this.buildUrl(endpoints.preLogin.fundDetail, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    CompareFunds(data) {
       
        let url = this.buildUrl(endpoints.preLogin.CompareFunds, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    passiveHome(data) {
     
        let url = this.buildUrl(endpoints.preLogin.passiveHome, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    HomePage(data) {
        let url = this.buildUrl(endpoints.preLogin.HomePage, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    download(data) {

        let url = this.buildUrl(endpoints.preLogin.download, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    SearchDownloads(data) {
        let url = this.buildUrl(endpoints.preLogin.SearchDownloads, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    ContactUs(data) {
        let url = this.buildUrl(endpoints.preLogin.ContactUs, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    enquiry(data) {
        let url = this.buildUrl(endpoints.preLogin.enquiry, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    perforamceGraph(data) {
        let url = this.buildUrl(endpoints.preLogin.perforamceGraph, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    perforamceGraphNew(data) {
        let url = this.buildUrl(endpoints.preLogin.perforamceGraphNew, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }

    STPCategory(data) {
        // console.log("In generateOtp", data)
        let url = this.buildUrl(endpoints.preLogin.STPCategory, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    PMSCategory(data) {
        // console.log("In generateOtp", data)
        let url = this.buildUrl(endpoints.preLogin.PMSCategory, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    PMSGraphData(data) {
        // console.log("In generateOtp", data)
        let url = this.buildUrl(endpoints.preLogin.PMSGraphDataTemp, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    PMSFundDetail(data) {
        // console.log("In generateOtp", data)
        let url = this.buildUrl(endpoints.preLogin.PMSFundDetail, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getSchemesForSEO(data) {
        // console.log("In generateOtp", data)
        let payloadData = {
            "api_name": "getSchemesForSEO"
        }
        let url = this.buildUrl(endpoints.preLogin.getSchemesForSEO, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(payloadData)).then(response => response);
    }
    getTotalExpratio(data) {
        let payloadData = {
            "api_name": "TotalExpratio"
        }
        let url = this.buildUrl(endpoints.preLogin.getTotalExpratio, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(payloadData)).then(response => response);
    }
    PMSHomePage(data) {
        let url = this.buildUrl(endpoints.preLogin.PMSHomePage, 'full');
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    SEODetails(data) {
        let url = this.buildUrl(endpoints.preLogin.SEODetails, "full");
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(
            (response) => response
        );
    }
}
