const infoMedia = process.env.NEXT_PUBLIC_INFOMEDIA;

const HeaderMain = {
    "PMS": [
        {
            "Heading": "Home",
            "url": "/",
            "class": "fas fa-home"
        },
        {
            "Heading": "Our Strategies",
            "url": "/our-strategies",
            "class": "fas fa-piggy-bank"
        },
        {
            "Heading": "Downloads",
            "url": "/download/document-disclosure",
            "class": "fas fa-file-download"
        },
        {
            "Heading": "About Us",
            "url": "/about-us/overview",
            "class": "fas fa-info-circle"
        },
        {
            "Heading": "NEWS & INSIGHTS",
            "url": "/Media-Room/Press-Release",
            "class": "fas fa-photo-video",
            "dropDown": [
                {
                    "title": "Blog",
                    "url": infoMedia
                },
                {
                    "title": "Press-Release",
                    "url": "/Media-Room/Press-Release"
                },
                {
                    "title": "Interviews",
                    "url": "/Media-Room/Press-Release",
                    "subDropDown": [
                        {
                            "title": "Print",
                            "url": "/Media-Room/Interviews/Print"
                        },
                        {
                            "title": "Online",
                            "url": "/Media-Room/Interviews/Online"
                        },
                        {
                            "title": "Television",
                            "url": "/Media-Room/Interviews/TV"
                        }
                    ]
                },
                {
                    "title": "News",
                    "url": "/Media-Room/Press-Release",
                    "subDropDown": [
                        {
                            "title": "Print",
                            "url": "/Media-Room/News/Print"
                        },
                        {
                            "title": "Online",
                            "url": "/Media-Room/News/Online"
                        },
                        {
                            "title": "Television",
                            "url": "/Media-Room/News/TV"
                        }
                    ]
                }
            ]
        },
        {
            "Heading": "Investor Charter",
            "url": "/assets/pdf/Investor_Charter_MOAMC.pdf",
            "class": "fas fa-info-circle"
        }
    ]
}

export default HeaderMain