import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import Image from '../atom/image/index';
import NextImage from '../atom/nextImage';
import HeaderMain from './mainjson';
import TopJson from './top';
import WhatsappPromoHeader from './WhatsappPromoHeader';

const Header = (props) => {

  const [topKey, setTopKey] = useState('PMS')
  const [activeNav, setActiveNav] = useState('home')
  const router = useRouter();
  const [mobileMode, setmobileMode] = useState(false);
  const [isClient, setIsClient] = useState(false)
  const disUrl = process.env.NEXT_PUBLIC_PARTNER_MODULE_URL + "pms"
  // const [containerHeight, setContainerHeight] = useState(65);
  useEffect(() => {
    let urlName = router.pathname;
    TopJson.map((curr, index) => {
      if (curr.url === urlName) {
        setTopKey(curr.filter);
  //setActiveNav(curr.Heading.toLowerCase())

      }
    })
  }, [topKey])

  useEffect(() => {
    let urlName = router.pathname;
    if (HeaderMain[topKey] !== undefined) {


      HeaderMain[topKey].map((curr, index) => {
        if (curr.url == urlName) {

          setActiveNav(curr.Heading.toLowerCase())

        }
      })
    }
  }, [router.pathname, topKey])
  //let dispatch = useDispatch();
  //const topKey = useSelector(state => state.Auth.headerKey)

  useEffect(() => {
    setIsClient(true)
    if (window.innerWidth <= "991") {
      // mobile screen
      setmobileMode(true)
    } else {
      // desktop screen
      setmobileMode(false)
    }
  }, []);

  // useEffect(() => {
  //     const handleResize = () => {
  //         if (window.innerWidth <= 1024) {
  //             setContainerHeight(45); // Set height for mobile screens
  //         } else {
  //             setContainerHeight(65); // Set default height for larger screens
  //         }
  //     };

  //     handleResize(); // Initial call
  //     window.addEventListener('resize', handleResize);

  //     return () => {
  //         window.removeEventListener('resize', handleResize);
  //     };
  // }, []);


  if (!isClient) return <></>

  const openMobile = () => {
    document.querySelector('body').classList.toggle('wsactive');
  }
  const topHeadingMobile = (e) => {
    let getCur = e.target.value;
    let getObj = "";
    TopJson.map((curr, index) => {
      if (curr.filter === getCur) {
        getObj = curr;
      }
    })
    if (getObj !== "") {
      let { internal, filter, url } = getObj;
      if (internal) {
        setTopKey(filter);
        localStorage.setItem('topKey', filter);
        window.location.href = url;
      } else {
        window.location.href = url;
      }
    }

  }
  const topHeading = (data, redirectFlag = true) => {
    let { internal, filter, url } = data;
    if (internal) {
      //alert(internal,'internal')
      setTopKey(filter);
      localStorage.setItem('topKey', filter);
      window.location.href = url;
    //props.history.push(url)
    //dispatch(HeaderKey(filter))


    } else {
      if (redirectFlag) {
        window.location.href = url;
      }
    }


  }

  let loginFlag = true;
  let quickInvest = true;
  let pageURL = "/pms/login";
  if (topKey === "ETF") {
    loginFlag = false;
    quickInvest = false;
  }
  if (topKey === "AIF") {
    loginFlag = true;
    quickInvest = false
    pageURL = "https://mfs.kfintech.com/MotilalAif/";
  }
  return (
    <React.Fragment>
      <div className="headerfull">
        <WhatsappPromoHeader />
        <div className="desktoplogo">
          <div className="row desktoplogopanel">
            <div className="col-md-2 col-auto moheader-logo-sec">
              <a id="wsnavtoggle" onClick={openMobile} className="wsanimated-arrow"><span></span></a>
              <a href="/" className="logo-a" style={{ verticalAlign: 'middle' }} aria-label='Mutualfund logo'>
                <div style={{ height: '65px', width: 'auto' }} className='logoheader'>
                  <NextImage
                    image={"/assets/images/prelogin-header/moamc-logo-new.png"}
                    alt={'Mf Logo'}
                    layout={'fill'}
                    objectFit="contain"
                  />
                </div>
                <Image
                  image="/assets/images/prelogin-header/moamc-logo-new.png"
                  class="d-inline-block d-sm-none"
                  alt={'ETF'} />
              </a>
            </div>
            <div className="col-auto mob-buttons">
              <div className="wsmobileheader clearfix">
                <div className="mob-login-sec">
                  <a href="/pms/login" className="headerlogin-btn-mob">
                    <div className="login-icon-container">
                      <NextImage
                        image="/assets/images/prelogin-header/login-icon.svg"
                        alt={'login icon'}
                        width={26}
                        height={25}
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="col pr-0">
              <div class="moamc-menu-sec">
                <div class="moamc-menu-productbox">
                  <div className="headertopleft clearfix">
                    <ul className="jeg_menu jeg_top_menu sf-js-enabled sf-arrows amc-product-desktop" style={{ touchAction: 'pan-y' }}>
                      {TopJson.map((curr, index) => {
                        return (
                          <li id="menu-item-704" key={index} className="menu-item menu-item-type-custom menu-item-object-custom menu-item-704">
                            <a className={`${curr.filter === topKey ? "webdomain-active" : ""}`} onClick={() => topHeading(curr)}>{curr.Heading}</a>
                          </li>
                        )
                      })}
                    </ul>
                    <ul className="groupwebsite-list amc-product-mob">
                      <li aria-haspopup="true" className="groupwebsitedropdown">
                        <select className="mobilet" value={topKey} onChange={(e) => topHeadingMobile(e)} >
                          {TopJson.map((curr, index) => {
                            return (
                              <option value={curr.filter} id={`menu-item-70${index}`} key={index} className="menu-item menu-item-type-custom menu-item-object-custom menu-item-704">{curr.Heading}</option>
                            )
                          })}
                        </select>
                      </li>
                    </ul>
                  </div>
                  <div class="col-auto des-search-sec">
                    <div className="quickicons-sec">
                      <a href="/faq" class="jeg_faq_toggle" aria-label='Click here to read the FAQs'>
                        <span class="help-tip topfaqtxt"><i class="fas fa-question-circle"></i><div class="infoHoverText">Faqs</div></span>
                      </a>
                      <a href="/contact-us" class="jeg_contact_toggle" aria-label='Click here to visit the contact us page'>
                        <span class="help-tip topcontacttxt"><i class="fas fa-phone-volume"></i><div class="infoHoverText">Contact Us</div></span>
                      </a>
                    </div>
                  </div>
                  <div className="headertopright clearfix">
                    <ul className="groupwebsite-list">
                      <li aria-haspopup="true" className="groupwebsitedropdown"><a href="#">Group Websites <i className="fas fa-angle-down"></i></a>
                        <ul className="sub-menu">
                          <li><a href="http://www.motilaloswalgroup.com/" target="_blank">Financial Services</a></li>
                          <li><a href="http://www.motilaloswal.com/" target="_blank">Broking &amp; Distribution</a></li>
                          <li><a href="http://www.motilaloswalgroup.com/Our-Businesses/Private-Wealth-Management" target="_blank">Private Wealth Management </a></li>
                          <li><a href="http://www.ahfcl.com/" target="_blank">Home Finance </a></li>
                          <li><a href="http://www.motilaloswalgroup.com/Our-Businesses/Institutional-Equities" target="_blank">Institutional Equities </a></li>
                          <li><a href="https://www.motilaloswalpe.com/" target="_blank">Private Equity</a></li>
                          <li><a href="http://www.motilaloswalgroup.com/Our-Businesses/Investment-Banking" target="_blank">Investment Banking </a></li>
                          <li><a href="http://www.motilaloswal.com/markets/commodity-market-live.aspx" target="_blank">Commodities </a></li>
                          <li><a href="http://www.motilaloswalre.com/" target="_blank">Real Estate </a></li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="moamc-menu-menubox">
                  <div className="wsmain clearfix">

                    <nav className="wsmenu clearfix">
                      <a href="#" class="wsmenu_menu_close" onClick={openMobile}><i class="wsmenu_closeicon"></i></a>
                      <div className="overlapblackbg" onClick={openMobile}></div>
                      <ul className="groupwebsite-list amc-product-mob">
                        <li aria-haspopup="true" className="groupwebsitedropdown">
                          <label class="lbl">Choose</label>
                          <select className="mobilet" value={topKey} onChange={(e) => topHeadingMobile(e)} >
                            {TopJson.map((curr, index) => {
                              return (
                                <option value={curr.filter} id={`menu-item-70${index}`} key={index} className="menu-item menu-item-type-custom menu-item-object-custom menu-item-704">{curr.Heading}</option>
                              )
                            })}
                          </select>
                        </li>
                      </ul>
                      {HeaderMain[topKey] !== undefined ? (
                        <ul className="wsmenu-list">
                          {HeaderMain[topKey].map((curr, index) => {
                            let urlName = router.pathname;
                            let flag = false;
                            let geturl = curr.url.split('/');
                            let urlSt = "/Download/regulatory-updates";
                            if (geturl.length >= 2 && curr.url !== urlSt) {
                              flag = true
                              let getsplite = urlName.split('/');
                              if (geturl[1] == getsplite[1]) {
                                flag = true
                              } else {
                                flag = false
                              }
                            } else {

                              // flag=false
                              //  if(activeNav.toLowerCase() ===
                              //  curr.Heading.toLowerCase()){
                              //   flag=true
                              //  }else{
                              //   flag=false
                              //  }
                            }
                            let getsplite = urlName.split('/');
                            if (curr.Heading === "NEWS & INSIGHTS") {
                              return (
                                <li aria-haspopup="true" key={index}>
                                  <a
                                    to={!mobileMode ? curr.url : ""}
                                    className={`navtext dropDownMainContainer ${flag
                                      ? "active"
                                      : ""
                                      }`}
                                    onClick={!mobileMode ? () => topHeading(curr) : () => topHeading(curr, false)}
                                  >
                                    <i className={curr.class}></i>{" "}
                                    <span className="dropDownHeader">{curr.Heading}</span>
                                    <div className="dropDownContainer">
                                      {
                                        curr.dropDown.map(item => {
                                          if (item.subDropDown) {
                                            return (
                                              <div className="subDropDownMainContainer">
                                                {
                                                  !mobileMode ?
                                                    <a href={item.url} className="dropDownItem">{item.title}</a>
                                                    :
                                                    <a className="dropDownItem">{item.title}</a>
                                                }
                                                <div className="subDropDownContainer">
                                                  {
                                                    item.subDropDown.map(subItem => {
                                                      return (
                                                        <a href={subItem.url} className="subDropDownItem">{subItem.title}</a>
                                                      )
                                                    })
                                                  }
                                                </div>
                                              </div>
                                            )
                                          } else {
                                            return (
                                              <a href={item.url} className="dropDownItem">{item.title}</a>
                                            )
                                          }
                                        })
                                      }
                                    </div>
                                  </a>
                                </li>
                              )
                            } else if (curr.Heading === "Investor Charter") {
                              return (
                                <li aria-haspopup="true" key={index}>
                                  <a className={`navtext ${flag ? "active" : ""}`} target="_blank" rel="noopener noreferrer" href={curr.url}>
                                    <i className={curr.class}></i> <span>{curr.Heading}</span>
                                  </a>
                                </li>
                              );
                            }

                            else {
                              return (
                                <li aria-haspopup="true" key={index}>
                                  <a to={curr.url} className={`navtext ${flag ? "active" : ""}`} onClick={() => topHeading(curr)}>
                                    <i className={curr.class}></i> <span>{curr.Heading}</span>
                                  </a>
                                </li>
                              );
                            }
                          })}
                        </ul>
                      ) : null}

                      {/* wsmenu-list d-none at the end*/}
                    </nav>
                  </div>

                  <div className="col-auto des-login-sec">
                    <a href={disUrl} className="headerloginsec-btn">
                      {/* <Image image="/assets/images/prelogin-header/login-icon.svg" />  */}
                      <div className="login-icon-desktop-container">
                        <NextImage
                          image="/assets/images/prelogin-header/login-icon.svg"
                          alt={'login icon'}
                          // layout={'fill'}
                          width={26}
                          height={25}
                        />
                      </div>
                      Distributor Login
                    </a>
                    {loginFlag ?
                      <a href={pageURL} className="headerloginsec-btn">
                        <div className="login-icon-desktop-container">
                          <NextImage
                            image="/assets/images/prelogin-header/login-icon.svg"
                            alt={'login icon'}
                            width={26}
                            height={25}
                          />
                        </div>
                        Investor Login
                      </a>
                      : null}
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>

      </div>

    </React.Fragment>
  )
}

export default Header;